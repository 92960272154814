<template>
  <div class="panel" panel-position="0">
    <!-- <section style="display: inline-flex; width: 100%; height: 100%; position: relative">-->

    <!-- TITLE -->
    <p class="navigation_title">
      <a @click="$emit('finished')">{{ $t('VideoLibrary.title') }}</a>
      <a> / </a>
      <a>{{ vdo_name }} </a>
    </p>

    <!-- PLAYER -->
    <section class="block_player center" v-if="sources != undefined">
      <div class="player_container">
        <div class="player" :class="format">
          <!--
          <vue-plyr ref="plyr">
            <video controls crossorigin playsinline>
              <source v-for="src in sources" :key="src" :size="src.resolution" :src="src.player" type="video/mp4" :previewThumbnails="thumbnail"/>
            </video>
        </vue-plyr>
        -->
          <Player :src="sources" :poster="thumbnail" :crop="true" :format="format" id="player"
            :controls="['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']" />
        </div>

      </div>

      <div class="videoActions">
        <h1>{{ $t('VideoOverview.share.title') }}</h1>
        <div v-if="downloadAvailable" class="share button  " @click="shareLinkToClipBoard">
          <img src="../../../../assets/link.png" />
          <p>{{ $t('VideoOverview.share.button') }}</p>
        </div>

        <div v-if="downloadAvailable" class="share button  " @click="embedToClipBoard">
          <img src="../../../../assets/embed.svg" />
          <p>{{ $t('VideoOverview.share.buttonEmbed') }}</p>
        </div>
        <!--
        <div v-if="downloadAvailable" class="share button  " @click="uploadYoutube">
          <img src="../../../../assets/youtube.svg" />
          <p>Share on Youtube</p>
        </div>
        -->

        <h1>{{ $t('VideoOverview.download') }}</h1>
        <div v-if="downloadAvailable" class="button download " @click="downloadVideo">
          <img src="../../../../assets/download_white.svg" /> <a>{{ $t('VideoOverview.downloadVideo') }}</a>
        </div>
        <p v-else class="button download center notAvailable">
          <a>{{ $t('VideoOverview.notDownloadable') }}</a>
        </p>
        <section>
          <h1>{{ $t('VideoOverview.subtitles') }}</h1>
          <section v-if="subtitles.id == undefined" style="display: flex; flex-direction: column">
            <h4 v-if="subtitles.language == ''" style="margin-top:5%;margin-bottom:4%;">
              {{ $t('VideoOverview.whatLanguage') }}</h4>
            <select v-model="languageSelected" v-if="subtitles.language == ''">
              <option value="">{{ $t('Languages.undefined') }}</option>
              <option value="af-ZA">{{ $t('Languages.af_ZA') }}</option>
              <option value="ar-AE">{{ $t('Languages.ar_AE') }}</option>
              <option value="ar-SA">{{ $t('Languages.ar_SA') }}</option>
              <option value="zh-CN">{{ $t('Languages.zh_CN') }}</option>
              <option value="zh-TW">{{ $t('Languages.zh_TW') }}</option>
              <option value="da-DK">{{ $t('Languages.da_DK') }}</option>
              <option value="nl-NL">{{ $t('Languages.nl_NL') }}</option>
              <option value="en-AU">{{ $t('Languages.en_AU') }}</option>
              <option value="en-GB">{{ $t('Languages.en_GB') }}</option>
              <option value="en-IN"> {{ $t('Languages.en_IN') }}</option>
              <option value="en-IE"> {{ $t('Languages.en_IE') }}</option>
              <option value="en-NZ"> {{ $t('Languages.en_NZ') }}</option>
              <option value="en-ZA"> {{ $t('Languages.en_ZA') }}</option>
              <option value="en-US"> {{ $t('Languages.en_US') }}</option>
              <option value="fr-FR"> {{ $t('Languages.fr_FR') }}</option>
              <option value="fr-CA"> {{ $t('Languages.fr_CA') }}</option>
              <option value="de-DE"> {{ $t('Languages.de_DE') }}</option>
              <option value="de-CH"> {{ $t('Languages.de_CH') }}</option>
              <option value="he-IL"> {{ $t('Languages.he_IL') }}</option>
              <option value="hi-IN"> {{ $t('Languages.hi_IN') }}</option>
              <option value="id-ID"> {{ $t('Languages.id_ID') }}</option>
              <option value="it-IT"> {{ $t('Languages.it_IT') }}</option>
              <option value="ja-JP"> {{ $t('Languages.ja_JP') }}</option>
              <option value="ko-KR"> {{ $t('Languages.ko_KR') }}</option>
              <option value="ms-MY"> {{ $t('Languages.ms_MY') }}</option>
              <option value="pt-PT"> {{ $t('Languages.pt_PT') }}</option>
              <option value="pt-BR"> {{ $t('Languages.pt_BR') }}</option>
              <option value="ru-RU"> {{ $t('Languages.ru_RU') }}</option>
              <option value="es-ES"> {{ $t('Languages.es_ES') }}</option>
              <option value="es-US"> {{ $t('Languages.es_US') }}</option>
              <option value="sv-SE"> {{ $t('Languages.sv_SE') }}</option>
              <option value="ta-IN"> {{ $t('Languages.ta_IN') }}</option>
              <option value="te-IN"> {{ $t('Languages.te_IN') }}</option>
              <option value="th-TH"> {{ $t('Languages.th_TH') }}</option>
              <option value="tr-TR"> {{ $t('Languages.tr_TR') }}</option>
              <option value="vi-VN"> {{ $t('Languages.vi_VN') }}</option>
            </select>
            <button class="button" :class="{ disabled: languageSelected == '' && subtitles.language == '' }"
              :disabled="languageSelected == '' && subtitles.language == ''" @click="askSub">
              {{ $t('VideoOverview.generationSub') }}
            </button>
          </section>

          <section v-else>
            <div v-if="subtitles.available">
              <p class="subtitlesText">
                {{ $t('VideoOverview.completedSub') }}
              </p>
              <div class="subtitlesTextWithImage">
                <img src="../../../../assets/share.svg" alt="" style="width:25px;" />
                <p><a :href="subtitles.edit_link" target="_blank"> {{ $t('VideoOverview.linkSub') }}</a></p>

              </div>

            </div>
            <div v-else class="subtitlesTextWithImage">
              <img src="../../../../assets/loadingArrows.svg" class="icon" alt="" style="width:30px;" />
              <p class="subtitlesText">
                {{ $t('VideoOverview.waitingSub') }}
              </p>
            </div>
          </section>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
//STORE
import Vuex from "vuex";
import store from "../../../../store/index";
import axios from "axios";

//import Player from "../../../../components/Object/Player.vue"

export default {
  data() {
    return {
      vdo_name: "",
      sources: undefined,
      subtitles: { language: "" },
      languageSelected: "",
      format: "",
      intervalSubtitles: null,
      thumbnail: null,
      shortId: null
    };
  },
  props: ["vdo_id"],
  emits: ["finished"],
  store: { store },
  //components: { Player },

  mounted: function () {
    this.getOneVideo({
      id: this.vdo_id
    }).then((video) => {

      this.vdo_name = video.vdo_name;
      this.format = video.vdo_format
      this.sources = video.vdo_files;
      this.thumbnail = video.vdo_thumbnail
      this.shortId = video.shortId

      if (video.vdo_subtitles != undefined) {
        this.subtitles = video.vdo_subtitles;
      }
      //this.language = video.vdo_subtitles.language
      this.$nextTick(() => {

        if (this.subtitles.available != undefined && !this.subtitles.available) {

          this.intervalerGetVideo();
        }

      });
    });
  },
  computed: {
    downloadAvailable() {
      return (this.sources?.original?.url != undefined)
    }
  },
  methods: {
    ...Vuex.mapActions(["getOneVideo"]),

    intervalerGetVideo() {
      // const self = this

      this.intervalSubtitles = setInterval(() => {
        this.getOneVideo({
          id: this.vdo_id
        }).then((vdo) => {
          this.subtitles = vdo.vdo_subtitles;
        });
      }, 15000);

    },
    async downloadVideo() {
      if (this.sources?.original?.url != undefined) {
        var fileLink = document.createElement("a");
        fileLink.href = this.sources?.original?.url;
        fileLink.setAttribute("download", this.vdo_name);
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove()
      } else {
        this.Toaster.error(this.$t('VideoOverview.error.download'))
      }
    },

    askSub() {
      axios
        .post("/videos/" + this.vdo_id + "/subtitle", {
          language:
            this.subtitles.language != ""
              ? this.subtitles.language
              : this.languageSelected,
        })
        .then((res) => {
          this.subtitles = res.data.vdo_subtitles;
          this.intervalerGetVideo(this.vdo_id);
        });
    },
    shareLinkToClipBoard(evt) {
      evt.stopPropagation();

      let shareLink = `${window.location.origin}/share/${this.shortId}`
      console.debug('Share link : ', shareLink)
      navigator.clipboard.writeText(shareLink).then(() => {
        this.Toaster.info(this.$t("CreativeSpace.info.linkCopied"));
      });
      
    },
    embedToClipBoard(evt) {
      evt.stopPropagation();
      console.log(window.location)
      let embedCode = `<iframe title="${this.vdo_name}" width="100%" height="100%" frameborder="0"
        allowfullscreen allowtransparency src="${window.location.origin}/share/${this.shortId}?embed=true">
      </iframe>`
      navigator.clipboard.writeText(embedCode).then(() => {
        this.Toaster.info(this.$t("CreativeSpace.info.linkCopied"));
      });

    },
    uploadYoutube(){
      console.log(this.$route)
        axios.get('/videos/authSocialNetwork?vdo_id='+this.vdo_id)
        .then((res)=>{
          console.log(res)
          window.open(res.data, '_blank');
          
        })
        .catch((e)=>{
        
          console.log(e)
        })
    }
  },

  watch: {
    subtitles(sub) {
      if (sub.available) {
        clearInterval(this.intervalSubtitles);
        this.$nextTick(() => {
          this.intervalSubtitles = null;
        });
      }
    }
  }
};
</script>


<style src="./style.css" scoped ></style>