<template>
  <div class="VideoLibrary">
    <h1 style="margin-left: 64px; margin-top: 32px; margin-bottom: 20px">
      {{ $t("VideoLibrary.title") }}
    </h1>

    <!-- PANEL VideoOverview -->
    <transition name="panel-slide">
      <VideoOverview v-if="VideoOverview != null" :vdo_id="VideoOverview"
        @finished="showVideosCardList = true; VideoOverview = null" class="panel" />
    </transition>

    <!-- VERTICAL BANNER -->
    <transition name="fade" v-show="VideoOverview != null">
      <div class="banner_container">
        <p class="vertical_banner">
          {{ $t("VideoLibrary.title") }}
        </p>
      </div>
    </transition>

    <!-- CLICK ZONE TO CLOSE PANEL -->
    <div v-if="VideoOverview != null" class="click_zone_to_close_panel"
      @click="showVideosCardList = true; VideoOverview = null"></div>

    <div v-if="videosListFetched == false" class="list_empty">
      <span class="loading_spinner" style="--color : var(--color2)"></span>
    </div>

    <div v-else-if="getVideos().length == 0" class="list_empty">
      <img class="center" src="../../assets/videos_empty.svg"/>
      <h1>{{ $t('videoLibrary.empty') }}</h1>
    </div>

    <div v-else-if="showVideosCardList == true" class="cardList center">
      <div v-for="video in getVideos()" v-bind:key="video" class="card" @click="launchVideoOverview(video._id)"
        :class="{ unclickable: in_processing(video) }">
        <DotsVdoMenu class="more" :lines="buttonsThreeDots(video)" :red="[$t('Delete'), $t('Cancel')]"
          @clicked="threeDotsClicked($event, video._id)" />
        <!-- BACKGROUND BLURED -->
        <div class="visual_container">

          <img class="bluredBackground" v-if="video.vdo_thumbnail != undefined" :src="video.vdo_thumbnail" />
          <img class="bluredBackground" v-else src="../../assets/vdo_thumbnail_placeholder.jpeg" />
        </div>

        <!-- VISUAL OF CARD -->
        <div class="visual_container">
          <p class="video_duration">
            {{ vdo_duration_render(video.vdo_duration) }}
          </p>
          <img v-if="
            video.vdo_thumbnail != undefined &&
            video.vdo_thumbnail == 'https://i.vimeocdn.com/video/default'
          " src="../../assets/vdo_thumbnail_placeholder.jpeg" />
          <img v-else-if="video.vdo_thumbnail != undefined" :src="video.vdo_thumbnail" />
          <img v-else src="../../assets/vdo_thumbnail_placeholder.jpeg" />
        </div>

        <!-- PROCESSING/REVIEW LABEL -->
        <p v-if="in_processing(video)" class="render_processing_message">
          <a>{{ render_processing_message(video)[0] }}</a>
          <a>{{ render_processing_message(video)[1] }}</a>
        </p>
        <div class="card-bottom">
          <div class="flex_spacebewteen">
            <editableText v-model="video.vdo_name" class="card-title" v-bind:id="video._id" @click.stop=""
              @update:modelValue="textUpdate($event, video._id, 'text')" :editable="true" type="text"
              :class="{ placeholder: video.vdo_name == '' }" :placeholder="$t('IndicateTitle.video')" />
          </div>
          <div class="flex_spacebewteen center">
            <p class="card-date">{{ displayDate(video.created_at) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//STORE
import Vuex from "vuex";

//COMPONENT - PANEL
import DotsVdoMenu from "../../components/Object/ContextualMenu/ContextualMenu";
import VideoOverview from "./components/VideoOverview/VideoOverview.vue";
import editableText from "../../components/Object/editableText";

//LIB
import { displayDate, renderMsShort } from "../../lib/date-time-duration";
import axios from "axios";

export default {
  data() {
    return {
      VideoOverview: null,
      showVideosCardList: true,
      fetchingVideosIntervaler: null,
      videosListFetched : false
    };
  },
  components: {
    VideoOverview,
    DotsVdoMenu,
    editableText,
  },

  mounted() {

    console.log(this.$route)
    if(this.$route.query.state != undefined){
      console.log('ocuocu')
      axios.post('/videos/'+JSON.parse(this.$route.query.state).vdo_id+'/socialNetwork', {
        code: this.$route.query.code
      })
      .then((res)=>{
        console.log('toro')
        console.log('resultat upload', res)
        window.location.href = "https://studio.youtube.com/"

      })
    }
    this.loadVideos().then(() =>{
      this.videosListFetched = true
    });

    //REFRESH VIDEO LIST EACH 30 sec
    this.fetchingVideosIntervaler = setInterval(() => {
      this.loadVideos();
    }, 30000);
  },
  unmounted(){
    this.$store.commit('CLEAR_VIDEOS')
  },
  beforeUnmount() {
    //CLEAR VIDEO REFRESH INTERVALER
    if (this.fetchingVideosIntervaler != null) {
      clearInterval(this.fetchingVideosIntervaler);
    }
  },
  computed: {
    ...Vuex.mapGetters(["getVideos", "vdoHasSubitles"])
  },
  methods: {
    ...Vuex.mapActions(["loadVideos", "deleteVideo", "updateVideo"]),
    launchVideoOverview(id) {
      this.VideoOverview = id;
      setTimeout(() => {
        this.showVideosCardList = false
      }, 1000)
    },
    displayDate,
    vdo_duration_render: renderMsShort,
    in_processing(video) {
      if (video.vdo_files == undefined) {
        return true;
      } else {
        return false;
      }
    },
    render_processing_message() {
      return [
        this.$t("VideoLibrary.inProgress.0"),
        this.$t("VideoLibrary.inProgress.1"),
      ];
    },
    buttonsThreeDots(video) {
      let array = [this.$t("Rename")];
      if (!this.in_processing(video)) {
        array.push(this.$t("Delete"));
      } else {
        array.push(this.$t('Cancel'))
      }

      return array;
    },
    threeDotsClicked(event, id) {
      var choice = event.line;

      switch (choice) {
        case this.$t("Rename"):
          $(".vdo_name#" + id + " > a")[0].click();
          break;
        case this.$t("Delete"):
        case this.$t('Cancel'):
          this.Toaster.prompt({
            title: this.$t('Toaster.areYouSure'),
            message: this.$t('VideoLibrary.prompt.will_delete_video'),
            validate: this.$t('Delete'),
            cancel: this.$t('Cancel')
          }).then((response) => {
            if (response == "validate") {
              this.deleteVideo(id)
                .then(() => {
                  this.Toaster.success(this.$t('VideoLibrary.success.deleteVideo'));
                })
                .catch(() => {
                  this.Toaster.error(this.$t('VideoLibrary.error.deleteVideo'));
                });
            }
          })
          break;
      }
    },
    textUpdate(value, id) {
      this.updateVideo({
        id: id,
        body: {
          vdo_name: value.replace(/\s/g, "") == "" ? "" : value,
        },
      }).catch(() => {
        this.Toaster.error(this.$t("Toaster.error.update"));
      });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped>

</style>